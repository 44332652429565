import slugify from '@sindresorhus/slugify'
import {s} from 'sanity-typed-schema-builder'

import {projectInfo} from './project_info'

export type ProjectType =
    | 'original_shortfilm'
    | 'original_featurefilm'
    | 'original_tvseries'
    | 'original_tvpilot'
    | 'original_webseries'
    | 'original_webpilot'
    | 'original_documentary'
    | 'original_music'
    | 'music_video'
    | 'commercial'

const project_types = [
    {
        title: 'Original Short Film',
        value: 'original_shortfilm',
    },
    {
        title: 'Original Feature Film',
        value: 'original_featurefilm',
    },
    {
        title: 'Original TV Series',
        value: 'original_tvseries',
    },
    {
        title: 'Original TV Pilot',
        value: 'original_tvpilot',
    },
    {
        title: 'Original Web Series',
        value: 'original_webseries',
    },
    {
        title: 'Original Web Pilot',
        value: 'original_webpilot',
    },
    {
        title: 'Original Documentary',
        value: 'original_documentary',
    },
    {
        title: 'Original Music',
        value: 'original_music',
    },
    {
        title: 'Music Video',
        value: 'music_video',
    },
    {
        title: 'Commercial',
        value: 'commercial',
    },
]

export const athomProjectTypeLegend: Record<string, string> = project_types.reduce((acc, cur) => {
    acc[cur.value] = cur.title.replace('Original ', '')
    return acc
}, {} as Record<string, string>)
export const ermazdProjectTypeLegend: Record<string, string> = project_types.reduce((acc, cur) => {
    acc[cur.value] = cur.title
    return acc
}, {} as Record<string, string>)

export const project = s.document({
    name: 'project',
    title: 'Project',
    fields: [
        {
            name: 'slug',
            description: 'URL Slug (autogenerated) (do not change)',
            type: s.slug({
                options: {source: 'title', maxLength: 64, slugify: (input) => slugify(input)},
            }),
        },
        {
            name: 'title',
            title: 'Title',
            description: 'Project Title',
            type: s.string({
                validation: (Rule) => Rule.required(),
                mock: (faker) => faker.music.songName(),
            }),
        },
        {
            name: 'visible',
            title: 'Visible',
            description: 'Whether to show this project on the site',
            type: s.boolean({initialValue: true}),
        },
        {
            name: 'shortDescription',
            title: 'Short Description',
            description: 'Short description - visible on homepage',
            optional: true,
            type: s.string({max: 180, mock: (faker) => faker.lorem.sentences(2)}),
        },
        {
            name: 'description',
            title: 'Description',
            description: 'Long form description - visible on project page',
            optional: true,
            type: s.text({mock: (faker) => faker.lorem.paragraph()}),
        },
        {
            name: 'featured',
            title: 'Featured',
            description: 'Whether to feature this project prominently',
            optional: true,
            type: s.boolean({mock: (faker) => faker.datatype.boolean()}),
        },
        {
            name: 'type',
            title: 'Type',
            description: 'Type of project',
            type: s.string({
                options: {
                    list: project_types as any,
                },
                mock: (faker) => faker.helpers.arrayElement(project_types.map((t) => t.value)),
            }),
        },
        {
            name: 'releaseDate',
            title: 'Release Date',
            optional: true,
            description: 'Date of project release',
            type: s.date({mock: (faker) => faker.date.past().toISOString()}),
        },
        {
            name: 'projectInfo',
            title: 'Project Info',
            description: 'Project Info',
            type: projectInfo,
        },
        {
            name: 'genre',
            title: 'Genre',
            optional: true,
            description: 'Genre',
            type: s.string({mock: (faker) => faker.music.genre()}),
        },
        {
            name: 'bannerImage',
            title: 'Banner Image',
            description: 'Banner Image',
            optional: true,
            type: s.image({
                fields: [
                    {
                        name: 'caption',
                        type: s.string({mock: (faker) => faker.lorem.sentence()}),
                        optional: true,
                    },
                ],
            }),
        },
        {
            name: 'posterImage',
            title: 'Poster Image',
            description: 'Poster Image',
            optional: true,
            type: s.image({
                fields: [
                    {
                        name: 'caption',
                        type: s.string({mock: (faker) => faker.lorem.sentence()}),
                        optional: true,
                    },
                ],
            }),
        },
        {
            name: 'featuredImage',
            title: 'Featured Image',
            description: 'Featured Image',
            optional: true,
            type: s.string({options: {list: ['bannerImage', 'posterImage']}}),
        },
        {
            name: 'media',
            title: 'Media',
            description: 'Project Media',
            optional: true,
            type: s.array({
                of: [
                    s.image({
                        fields: [{name: 'caption', type: s.string(), optional: true}],
                    }),
                    s.file({fields: [{name: 'caption', type: s.string(), optional: true}]}),
                ],
            }),
        },
        {
            name: 'btsMedia',
            title: 'Behind The Scenes Media',
            description: 'Behind The Scenes Media',
            optional: true,
            type: s.array({
                of: [
                    s.image({
                        fields: [{name: 'caption', type: s.string(), optional: true}],
                    }),
                    s.file({fields: [{name: 'caption', type: s.string(), optional: true}]}),
                ],
            }),
        },
    ],
})

export const projectSchema = project.schema()

export type Project = s.infer<typeof project>

export type ProjectImageMedia = Exclude<Project['media'], {_type: 'file'}>

export function hasImageMedia(p: Project): boolean {
    return p?.media?.some((m) => m._type === 'image') ?? false
}

export function getImageMedia(p: Project): NonNullable<ProjectImageMedia> {
    return p?.media?.filter((m) => m._type === 'image') ?? []
}

export function hasFeaturedImage(p: Project): boolean {
    return (
        (p.featuredImage === 'bannerImage' && p.bannerImage !== undefined) ||
        (p.featuredImage === 'posterImage' && p.posterImage !== undefined)
    )
}

export function getFeaturedImage(
    p: Project
): Project['bannerImage'] | Project['posterImage'] | null {
    if (p.featuredImage === 'bannerImage' && p.bannerImage) {
        return p.bannerImage
    } else if (p.featuredImage === 'posterImage' && p.posterImage) {
        return p.posterImage
    } else {
        return null
    }
}
