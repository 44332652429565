import {ImageUrlBuilder} from '@sanity/image-url/lib/types/builder'
import classNames from 'classnames'
import dayjs from 'dayjs'
import useEmblaCarousel from 'embla-carousel-react'
import {union} from 'lodash-es'
import Image from 'next/image'

import {Project} from '../schemas'
import {hasImageMedia, ProjectType} from '../schemas/project'

type Detail =
    | 'writer'
    | 'director'
    | 'producer'
    | 'editor'
    | 'cast'
    | 'releaseDate'
    | 'directorOfPhotography'
    | 'executiveProducer'
    | 'cinematographer'
    | 'composer'
    | 'music'
    | 'productionDesign'
    | 'costumeDesign'
    | 'musicVocals'
    | 'musicProduction'
    | 'musicMastering'
    | 'storyBy'
    | 'associateProducer'
    | 'assistantDirector'
    | 'productionAssistant'
    | 'assistantCamera'
    | 'stylist'
    | 'hairAndMakeUp'
    | 'colorist'
    | 'equipment'

type DisplayDetail =
    | Detail
    | 'film_by'
    | 'writer_director'
    | 'genre'
    | 'director_producer'
    | 'editor_colorist'
type DisplayDetailList = Partial<Record<DisplayDetail, string[]>>

interface DetailConfig {
    upper?: DisplayDetail[]
    lower?: DisplayDetail[]
    additionalSections?: AdditionalSection[]
}

interface AdditionalSection {
    title: string
    details: DisplayDetail[]
}

interface AdditionalSectionData {
    title: string
    details: DisplayDetailList
}

interface DisplayData {
    upper: DisplayDetailList
    lower: DisplayDetailList
    additionalSections?: AdditionalSectionData[]
}

type ProjectConfig = Record<ProjectType, DetailConfig>

const defaultConfig: DetailConfig = {
    upper: [
        'film_by',
        'writer_director',
        'writer',
        'director',
        'producer',
        'releaseDate',
        'cast',
        'directorOfPhotography',
        'genre',
    ],
    lower: [
        'executiveProducer',
        'storyBy',
        'associateProducer',
        'cinematographer',
        'assistantDirector',
        'productionAssistant',
        'assistantCamera',
        'composer',
        'music',
        'productionDesign',
        'costumeDesign',
        'stylist',
        'hairAndMakeUp',
        'colorist',
        'equipment',
    ],
}

const musicVideoConfig: DetailConfig = {
    upper: [
        'film_by',
        'writer_director',
        'writer',
        'director_producer',
        'director',
        'producer',
        'directorOfPhotography',
        'releaseDate',
        'cast',
        'genre',
    ],
    lower: [
        'executiveProducer',
        'storyBy',
        'associateProducer',
        'cinematographer',
        'assistantDirector',
        'productionAssistant',
        'assistantCamera',
        'composer',
        'music',
        'productionDesign',
        'costumeDesign',
        'stylist',
        'hairAndMakeUp',
        'colorist',
        'equipment',
    ],
    additionalSections: [
        {
            title: 'Track Info',
            details: ['musicVocals', 'musicProduction', 'musicMastering'],
        },
    ],
}

const projectConfigs: ProjectConfig = {
    original_documentary: defaultConfig,
    original_shortfilm: defaultConfig,
    original_featurefilm: defaultConfig,
    original_tvpilot: defaultConfig,
    original_tvseries: defaultConfig,
    original_webseries: defaultConfig,
    original_webpilot: defaultConfig,
    original_music: defaultConfig,
    music_video: musicVideoConfig,
    commercial: defaultConfig,
}

const legend: Record<DisplayDetail, string> = {
    writer: 'Written by',
    director: 'Directed by',
    producer: 'Produced by',
    editor: 'Edited by',
    film_by: 'a Film by',
    cast: 'Starring',
    executiveProducer: 'Executive Producer',
    cinematographer: 'Cinematography',
    directorOfPhotography: 'Director of Photography',
    composer: 'Composer',
    music: 'Music',
    productionDesign: 'Production Design',
    costumeDesign: 'Costume Design',
    releaseDate: 'Release',
    writer_director: 'Written and Directed by',
    musicVocals: 'Vocalist',
    musicProduction: 'Produced by',
    genre: 'Genre',
    storyBy: 'Story by',
    director_producer: 'Directed and Produced by',
    associateProducer: 'Associate Producer',
    assistantDirector: 'Assistant Director',
    productionAssistant: 'Production Assistant',
    assistantCamera: 'Assistant Camera',
    stylist: 'Stylist',
    hairAndMakeUp: 'Hair & Make Up',
    colorist: 'Colorist',
    editor_colorist: 'Edited & Colored By',
    equipment: 'Equipment',
    musicMastering: 'Mastered By',
}

export interface ProjectDetailsStyles {
    container?: string
    title?: string
    description?: string
    detailLegend?: string
    detailContent?: string
    btsTitle?: string
    sectionTitle?: string
}

const defaultStyles: ProjectDetailsStyles = {
    container: 'container flex flex-col px-2 md:px-12 mx-auto',
    title: 'text-[22px] lg:text-[52px] uppercase mb-4',
    description: 'w-full mb-8 text-[14px] lg:text-[18px] leading-normal lg:w-2/3',
    detailLegend: 'text-[14px] lg:text-[18px] uppercase',
    detailContent: 'text-[14px] lg:text-[18px] uppercase',
    btsTitle: 'text-2xl md:text-[52px] uppercase lg:text-[52px]',
    sectionTitle: 'text-xl md:text-2xl uppercase',
}

interface ProjectDetailsProps {
    project: Project
    imageUrlBuilder: ImageUrlBuilder
    inverted?: boolean
    styles?: ProjectDetailsStyles
}

const collapseGroups: Partial<Record<DisplayDetail, Detail[]>> = {
    film_by: ['writer', 'director', 'producer'],
    writer_director: ['writer', 'director'],
    director_producer: ['director', 'producer'],
    editor_colorist: ['editor', 'colorist'],
}

function collapseDetails(project: Project) {
    const projectInfo = project.projectInfo as Record<DisplayDetail, string[] | undefined>
    for (const group_name in collapseGroups) {
        const detailFields = collapseGroups[group_name as DisplayDetail]
        if (!detailFields) {
            continue
        }
        const data = [...detailFields.map((detail) => projectInfo[detail] ?? [])]
        if (data.every((d) => d.length === 1) && union(...data).length === 1) {
            projectInfo[group_name as DisplayDetail] = data[0]
            for (const detailField of detailFields) {
                delete projectInfo[detailField]
            }
        }
    }
    return projectInfo
}

function mapDetails(project: Project) {
    const config = projectConfigs[project.type as ProjectType]
    const projectInfo = collapseDetails(project)
    if (project.genre) {
        projectInfo['genre' as DisplayDetail] = [project.genre]
    }
    if (project.releaseDate) {
        projectInfo['releaseDate' as DisplayDetail] = [dayjs(project.releaseDate).format('YYYY')]
    }
    const details: DisplayData = {
        upper: {},
        lower: {},
        additionalSections: [],
    }
    config.upper?.forEach((detail) => {
        if (detail in projectInfo && (projectInfo?.[detail]?.length ?? 0) > 0) {
            details.upper[detail] = projectInfo[detail] ?? []
        }
    })
    config.lower?.forEach((detail) => {
        if (detail in projectInfo && (projectInfo?.[detail]?.length ?? 0) > 0) {
            details.lower[detail] = projectInfo[detail] ?? []
        }
    })
    config.additionalSections?.forEach((section) => {
        const sectionDetails: Partial<Record<DisplayDetail, string[]>> = {}
        section.details.forEach((detail) => {
            if (detail in projectInfo && (projectInfo?.[detail]?.length ?? 0) > 0) {
                sectionDetails[detail] = projectInfo[detail] ?? []
            }
        })
        if (Object.keys(details).length > 0) {
            details?.additionalSections?.push({
                title: section.title,
                details: sectionDetails,
            })
        }
    })
    return details
}

export function ProjectDetails(props: ProjectDetailsProps) {
    const [emblaRef, emblaApi] = useEmblaCarousel({loop: false, startIndex: 1})
    const project = props.project
    const details = mapDetails(project)

    function printDetailLine(line: Partial<Record<DisplayDetail, string[]>>) {
        return Object.entries(line).map(([key, value]: [string, string[]], index: number) => {
            return (
                <div
                    className={classNames('flex flex-col items-start', {
                        'md:col-span-2': key === 'cast',
                    })}
                    key={index}
                >
                    <span className={props.styles?.detailLegend ?? defaultStyles.detailLegend}>
                        {legend[key as DisplayDetail]}
                    </span>
                    {value.map((data, index) => (
                        <span
                            key={index}
                            className={classNames(
                                props.styles?.detailContent ?? defaultStyles.detailContent
                            )}
                        >
                            {data}
                        </span>
                    ))}
                </div>
            )
        })
    }

    return (
        <div className={props.styles?.container ?? defaultStyles.container}>
            <div className="flex flex-col mt-8 mb-4">
                <h1 className={props.styles?.title ?? defaultStyles.title}>
                    {project?.title ?? 'No title found'}
                </h1>
                <p className={props.styles?.description ?? defaultStyles.description}>
                    {project?.description ?? 'No description found.'}
                </p>
                <div
                    id="details"
                    className="grid grid-cols-1 lg:grid-cols-3 auto-rows-auto gap-4 lg:gap-x-8 lg:gap-y-12 mt-4 lg:mt-12"
                >
                    {printDetailLine(details.upper)}
                    {project?.bannerImage && (
                        <div className="col-span-full">
                            <Image
                                src={props.imageUrlBuilder.image(project.bannerImage).url()}
                                className="rounded-3xl"
                                width={1440}
                                height={900}
                                alt={project.bannerImage.caption ?? 'Banner image'}
                            />
                        </div>
                    )}
                    {hasImageMedia(project) && (
                        <div className="flex flex-col lg:flex-row col-span-full">
                            {project?.media
                                ?.filter((m) => m._type === 'image')
                                .slice(0, 2)
                                .map((media, index) => {
                                    return (
                                        <div
                                            className={`flex-auto ${
                                                index === 0 ? 'mb-4 lg:mb-0 lg:mr-4' : ''
                                            }`}
                                            key={index}
                                        >
                                            <Image
                                                src={props.imageUrlBuilder.image(media).url()}
                                                className="rounded-3xl"
                                                width={1280}
                                                height={720}
                                                alt={media.caption ?? 'Banner image'}
                                            />
                                        </div>
                                    )
                                })}
                        </div>
                    )}
                    {printDetailLine(details.lower)}
                    {details?.additionalSections?.map((section, index) => (
                        <>
                            <div key={index} className="col-span-full">
                                <h3
                                    className={
                                        props.styles?.sectionTitle ?? defaultStyles.sectionTitle
                                    }
                                >
                                    {section.title}
                                </h3>
                            </div>
                            {printDetailLine(section.details)}
                        </>
                    ))}
                </div>
            </div>
            {Array.isArray(project?.btsMedia) && project?.btsMedia?.length > 0 && (
                <div className="flex flex-col mt-12 lg:mt-24">
                    <div className="flex flex-row justify-between w-full mb-4">
                        <h3 className={props.styles?.btsTitle ?? defaultStyles.btsTitle}>
                            Behind the scenes
                        </h3>
                        <div className="flex-row items-center justify-between hidden lg:flex">
                            <button
                                className="mr-3 scale-75 rotate-180"
                                onClick={() => {
                                    emblaApi?.canScrollPrev() && emblaApi?.scrollPrev()
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="53.739"
                                    height="53.758"
                                    viewBox="0 0 53.739 53.758"
                                >
                                    <path
                                        id="Path_1161"
                                        data-name="Path 1161"
                                        d="M280.432,191.739H238v5.275h42.432l-23.741,23.741h7.461l26.379-26.379L264.153,168h-7.461Z"
                                        transform="translate(-237.5 -167.498)"
                                        fill={'#000'}
                                        stroke="#fff"
                                        strokeWidth={'1'}
                                    />
                                </svg>
                            </button>
                            <button
                                className="ml-3 scale-75"
                                onClick={() => {
                                    emblaApi?.canScrollNext() && emblaApi?.scrollNext()
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="53.739"
                                    height="53.758"
                                    viewBox="0 0 53.739 53.758"
                                >
                                    <path
                                        id="Path_1161"
                                        data-name="Path 1161"
                                        d="M280.432,191.739H238v5.275h42.432l-23.741,23.741h7.461l26.379-26.379L264.153,168h-7.461Z"
                                        transform="translate(-237.5 -167.498)"
                                        fill={'#fff'}
                                        stroke="#fff"
                                        strokeWidth={'1'}
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="w-full overflow-hidden" ref={emblaRef}>
                        <div className="flex">
                            {project.btsMedia.map((image, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`relative flex-c lg:flex-cc ${
                                            index === 0 ? 'lg:mr-2' : 'lg:mx-2'
                                        }`}
                                    >
                                        <Image
                                            src={props.imageUrlBuilder.image(image).url()}
                                            className="rounded-3xl"
                                            width={1440}
                                            height={900}
                                            alt={image.caption ?? 'Banner image'}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
