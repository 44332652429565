import {s} from 'sanity-typed-schema-builder'

export const projectInfo = s.object({
    fields: [
        {
            name: 'writer',
            title: 'Writer',
            description: 'Writer(s)',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'director',
            title: 'Director',
            description: 'Director(s)',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'assistantDirector',
            title: 'Assistant Director',
            description: 'Assistant Director(s)',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'producer',
            title: 'Producer',
            description: 'Producer(s)',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'associateProducer',
            title: 'Associate Producer',
            description: 'Associate Producer(s)',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'executiveProducer',
            title: 'Executive Producer',
            description: 'Executive Producer(s)',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'cinematographer',
            title: 'Cinematographer',
            description: 'Cinematographer(s)',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'directorOfPhotography',
            title: 'Director of Photography',
            description: 'Director(s) of Photography',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'assistantCamera',
            title: 'Assistant Camera',
            description: 'Assistant(s) Camera',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'editor',
            title: 'Editor',
            description: 'Editor(s)',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'colorist',
            title: 'Colorist',
            description: 'Colorist(s)',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'productionAssistant',
            title: 'Production Assistant',
            description: 'Production Assistant(s)',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'composer',
            title: 'Composer',
            description: 'Composer(s)',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },

        {
            name: 'music',
            title: 'Music',
            description: 'Music',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'musicProduction',
            title: 'Production (Music)',
            description: 'Music Production',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'musicVocals',
            title: 'Vocals',
            description: 'Vocals',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'musicMastering',
            title: 'Mastering',
            description: 'Mastering',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'productionDesign',
            title: 'Production Design',
            description: 'Production Designer(s)',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'costumeDesign',
            title: 'Costume Design',
            description: 'Costume Designer(s)',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'stylist',
            title: 'Stylist',
            description: 'Stylist(s)',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'hairAndMakeUp',
            title: 'Hair & Make Up',
            description: 'Hair & Make Up',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'cast',
            title: 'Cast Member(s)',
            description: 'Cast Member(s)',
            optional: true,
            type: s.array({
                of: [s.string()],
                mock: (faker) => [
                    faker.name.fullName(),
                    faker.name.fullName(),
                    faker.name.fullName(),
                ],
            }),
        },
        {
            name: 'storyBy',
            title: 'Story By',
            description: 'Story By',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
        {
            name: 'equipment',
            title: 'Equipment',
            description: 'Equipment',
            optional: true,
            type: s.array({of: [s.string()], mock: (faker) => [faker.name.fullName()]}),
        },
    ],
})

export type ProjectInfoType = s.infer<typeof projectInfo>
