import slugify from '@sindresorhus/slugify'
import {s} from 'sanity-typed-schema-builder'

export const newsArticle = s.document({
    name: 'newsArticle',
    title: 'News Article',
    fields: [
        {
            name: 'slug',
            description: 'URL Slug (autogenerated) (do not change)',
            type: s.slug({
                options: {source: 'title', maxLength: 64, slugify: (input) => slugify(input)},
            }),
        },
        {
            name: 'title',
            title: 'Title',
            description: 'Article Title',
            type: s.string({
                validation: (Rule) => Rule.required(),
                mock: (faker) => faker.lorem.sentence(),
            }),
        },
        {
            name: 'publication',
            title: 'Publication Name',
            description: 'Name of publication where article was published',
            type: s.string({
                validation: (Rule) => Rule.required(),
                mock: (faker) => faker.company.name(),
            }),
        },
        {
            name: 'date',
            title: 'Publication Date',
            description: 'Date article was published',
            type: s.date({
                validation: (Rule) => Rule.required(),
                mock: (faker) => faker.date.past().toISOString(),
            }),
        },
        {
            name: 'description',
            title: 'Description',
            description: 'Excerpt or summary of article',
            type: s.string({mock: (faker) => faker.lorem.paragraph()}),
        },
        {
            name: 'image',
            title: 'Image',
            description: 'Article image',
            optional: true,
            type: s.image(),
        },
        {
            name: 'url',
            title: 'URL',
            optional: true,
            description: 'Article source URL (permalink recommended)',
            type: s.url({mock: (faker) => faker.internet.url()}),
        },
    ],
})

export const newsArticleSchema = newsArticle.schema()

export type NewsArticle = s.infer<typeof newsArticle>
