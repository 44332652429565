import {SanityClient} from '@sanity/client'
import Image from 'next/image'
import {ImageProps} from 'next/image'
import {useNextSanityImage} from 'next-sanity-image'

import {SanityImageSourceWithMetadata} from '../types'
export interface SanityImageProps extends Omit<ImageProps, 'src'> {
    sanitySrc: SanityImageSourceWithMetadata
    sanityClient: SanityClient
}

export function SanityImage(props: SanityImageProps) {
    const {sanitySrc, sanityClient, ...passthruProps} = props
    const imageProps = useNextSanityImage(props.sanityClient, props.sanitySrc)
    return (
        <Image
            {...imageProps}
            {...passthruProps}
            placeholder="blur"
            blurDataURL={sanitySrc.asset?.metadata?.lqip}
        />
    )
}
