import 'keen-slider/keen-slider.min.css'

import {SanityClient} from '@sanity/client'
import {useKeenSlider} from 'keen-slider/react'
import {ImageProps} from 'next/image'

import {SanityImageSourceWithMetadata} from '../types'
import {SanityImage} from './SanityImage'

type AdvancedSanityImage = SanityImageSourceWithMetadata & {
    caption?: string
}

export interface CarouselImageProps {
    image: AdvancedSanityImage
    className?: string
    imageProps: Partial<ImageProps>
    sanityClient: SanityClient
}

export function CarouselImage({image, className, imageProps, sanityClient}: CarouselImageProps) {
    return (
        <div className={`keen-slider__slide ${className ?? ''}`}>
            <SanityImage
                {...imageProps}
                sanitySrc={image}
                sanityClient={sanityClient}
                alt={image.caption ?? ''}
            />
        </div>
    )
}

export interface CarouselProps {
    images: AdvancedSanityImage[]
    className?: string
    sanityClient: SanityClient
    slideClassName?: string
    imageProps?: Partial<ImageProps>
}

export function SimpleCarousel({
    images,
    className,
    sanityClient,
    slideClassName,
    imageProps,
}: CarouselProps) {
    const [sliderRef] = useKeenSlider()

    return (
        <div className={`keen-slider ${className ?? ''}`} ref={sliderRef}>
            {images.map((image, index) => (
                <CarouselImage
                    key={index}
                    image={image}
                    className={slideClassName}
                    sanityClient={sanityClient}
                    imageProps={imageProps ?? {}}
                />
            ))}
        </div>
    )
}

export function AutoplayCarousel({
    images,
    className,
    sanityClient,
    slideClassName,
    imageProps,
    interval,
}: CarouselProps & {interval?: number}) {
    const [sliderRef] = useKeenSlider(
        {
            loop: true,
        },
        [
            (slider) => {
                let timeout: string | number | NodeJS.Timeout | undefined
                let mouseOver = false
                function clearNextTimeout() {
                    clearTimeout(timeout)
                }
                function nextTimeout() {
                    clearTimeout(timeout)
                    if (mouseOver) return
                    timeout = setTimeout(() => {
                        slider.next()
                    }, interval ?? 2000)
                }
                slider.on('created', () => {
                    slider.container.addEventListener('mouseover', () => {
                        mouseOver = true
                        clearNextTimeout()
                    })
                    slider.container.addEventListener('mouseout', () => {
                        mouseOver = false
                        nextTimeout()
                    })
                    nextTimeout()
                })
                slider.on('dragStarted', clearNextTimeout)
                slider.on('animationEnded', nextTimeout)
                slider.on('updated', nextTimeout)
            },
        ]
    )
    return (
        <div className={`keen-slider ${className ?? ''}`} ref={sliderRef}>
            {images.map((image, index) => (
                <CarouselImage
                    key={index}
                    image={image}
                    className={slideClassName}
                    sanityClient={sanityClient}
                    imageProps={imageProps ?? {}}
                />
            ))}
        </div>
    )
}
